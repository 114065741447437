body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: Roboto, "Helvetica Neue", sans-serif; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --body-bg-light: #f0f0f0;
  --body-bg-dark: #243C5C;
  --sidebar-bg-light: rgb(37, 37, 66);
  --sidebar-bg-dark: #555;
  --main-bg-light: #fff;
  --main-bg-dark: #444;
  --button-bg-light: #007bff;
  --button-hover-bg-light: #0056b3;
  --button-bg-dark: #bbb;
  --button-hover-bg-dark: #999;
  --button-color-light: #fff;
  --button-color-dark: #333;
  --table-border-light: #ddd;
  --table-border-dark: red;
  --text-color-dark: #fff;
}

.main
{
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
}

.header
{
  width: 80%; 
  margin-left: 20%;
}

.popup-content
{
  padding: 0px;
}



/*------ CSS for Side bar ---- */
/* Define styles for success toast */
.toast-success {
  background-color: green;
  color: white;
}

/* Define styles for warning toast */
.toast-warning {
  background-color: orange;
  color: white;
}


/* Positioning and Styling for Loader Overlay */
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5000000;
  pointer-events: none;
}

/* Disabling interactions when loading */
.loading-disabled {
  pointer-events: none;
  opacity: 0.5;
}

.no-scroll
{
  pointer-events: none;
}


/* 
body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
} */

/* From Uiverse.io by Ali-Tahmazi99 */ 
.loading {
  width: 120px;
  height: 120px;
  position: relative;
}

.d1 , .d2 {
  border-radius: 50%;
}

.loading .d1 {
  border: 3px solid var(--body-main-dark-color);
  width: 145px;
  height: 145px;
  position: absolute;
  border-left-color: transparent;
  animation: load161 1.5s linear infinite;
}
.loading .logo img {
  width: 100px;
  height: 100px;
  position: absolute;
  justify-content: center;
  align-items: center;
  justify-items: center;
  transform: translate(-50%, -50%);
  background-color: #1e3a56;
  border-radius: 50%;
  z-index: 10;
  top: 64%;
  left: 64%;
}
.loading .d2 {
  border: 3px solid var(--body-main-dark-color);
  width: 130px;
  height: 130px;
  border-top-color: transparent;
  border-right-color: transparent;
  margin: 7.5px;
  animation: load2812 2s linear infinite;
}

@keyframes load161 {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes load2812 {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(-360deg);
  }
}